<template>
    <v-row>
        <v-col cols=12>
            <v-spacer />
            <i-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            />
        </v-col>
        <v-col cols=12>
            <v-data-table
                :headers="headers"
                :items="rows"
                :search="search"
            />
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'DataTable',

        props: {
            dataset: {
                type: Object,
                required: true,
            },
        },

        data: () => ({
            search: '',
        }),

        computed: {
            headers() {
                return this.dataset.columns.map(
                    (item) => ({
                        text: item, value: item, sortable: true, align: 'start',
                    }),
                );
            },
            rows() {
                return this.dataset.rows;
            },
        },
    };
</script>
